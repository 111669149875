import React from "react"
import { H1 } from "../components/text/H1"
import { H3 } from "../components/text/H3"
import Layout from "../components/Layout"
import SplitView from "../components/SplitView"
import ContactUsIllustration from "../images/contact_us_illustration.svg"
import SplitViewImage from "../components/SplitViewImage"
import { LinkType, OutsideLink } from "../components/text/OutsideLink"
import { Button } from "../components/text/Button"

export interface KontaktProps {}

const Kontakt: React.SFC<KontaktProps> = () => {
  return (
    <Layout>
      <SplitView
        heading={
          <H1 text="Kontaktieren Sie uns" className="mb-16 text-center" />
        }
        elementLeft={
          <SplitViewImage
            src={ContactUsIllustration}
            alt="Kontaktmöglichkeiten Illustration"
          />
        }
        elementRight={
          <>
            <H3 text="Wir freuen uns von Ihnen zu hören" />
            <ul>
              <li className="mb-4 flex items-center justify-center lg:justify-start">
                <svg
                  className="inline-block w-6 h-6 mr-3 text-primary-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
                <span>
                  <OutsideLink
                    href="https://goo.gl/maps/iKM8hYkN7FpScuz39"
                    input="Kroatengasse 3, 97070 Würzburg"
                  />
                </span>
              </li>
              <li className="mb-4 flex items-center justify-center lg:justify-start">
                <svg
                  className="inline-block w-6 h-6 mr-3 text-primary-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
                <span>
                  <OutsideLink type={LinkType.phone} href="+49 17643746921" />
                </span>
              </li>
              <li className="mb-4 flex items-center justify-center lg:justify-start">
                <svg
                  className="inline-block w-6 h-6 mr-3 text-primary-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  ></path>
                </svg>
                <span>
                  <OutsideLink
                    type={LinkType.mail}
                    href="jonas@diepsychotherapieapp.de"
                  />
                </span>
              </li>
            </ul>
            <Button
              text="Beratungsgespräch vereinbaren"
              to="https://calendly.com/psychotherapie-jonas/einfuhrungsgespraech?month=2021-03&date=2021-03-11"
              className="text-center"
              newTab
            />
          </>
        }
      />
    </Layout>
  )
}

export default Kontakt
